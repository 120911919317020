<!--  -->
<template>
  <div class="div">
    <div v-show="isShow">
      <div class="banner">
        <div class="word1">产品介绍</div>
        <div class="txt1">领先的智能化碳信息技术平台</div>
      </div>

      <div class="outer1 BLANK">
        <div class="word1">
          <!-- 双碳 -->
        </div>
        <div class="horizontally">
          <div class="top">
            <div class="img">
              <img src="../../assets/product/1.png" alt />
            </div>
            <div class="con">
              <div class="title">碳核查平台</div>
              <div class="con">
                <ul>
                  <li>支持碳监测计划的快速编订和归档，为企业碳业务合规提供数字化手段</li>
                  <li>支持多种数据来源及确定方法，确保监测计划平稳执行。</li>
                  <li>预置国家排放核算方法，参数推荐值及排放因子，供用户快速引用。</li>
                  <li>自动实时计算碳排放，企业可随时掌握碳排放的精准数据，及时对碳排放纠偏。</li>
                  <li>自动生成报告，降低披露成本</li>
                </ul>
              </div>
              <div class="but">
                <el-button @click="click(0)">申请试用</el-button>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="con">
              <div class="title">低碳学堂</div>
              <div class="con">
                涵盖顾问、知识、资讯、课程等多个版块，
                未来还将不断扩展。顾问服务解答低碳领域各种问题，其它版块覆盖
                低碳领域知识、新闻、观点、市场、计算、管理等各类服务，打造全
                方位、一站式碳顾问。
              </div>
              <div class="but">
                <el-button @click="click(1)">申请试用</el-button>
              </div>
            </div>
            <div class="img">
              <img src="../../assets/product/2.png" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="outer3 BLANK">
        <div class="word3">ESG管理架构</div>
        <div class="horizontally">
          <div class="txt3">
            <template v-for="(item, index) in data">
              <div class="div1" :key="index">
                <img :src="item.img" alt />
                <p class="word">{{ item.word }}</p>
                <p class="txt">{{ item.txt }}</p>
              </div>
              <div class="div2" v-if="index !== data.length - 1" :key="10 + index"></div>
            </template>
          </div>
        </div>
      </div>
      <el-timeline v-if="equipment == 1" style="margin-top: 50px">
        <el-timeline-item timestamp="双碳" placement="top" color="#056BC6"></el-timeline-item>
        <div class="timeline">
          <el-timeline-item placement="top" :timestamp="equipment == 1 ? '碳核查平台' : ''">
            <div class="con">
              <ul style="padding-left: 0px">
                <li>支持碳监测计划的快速编订和归档,为企业碳业务合规提供数字化手段</li>
                <li>支持多种数据来源及确定方法,确保监测计划平稳执行。</li>
                <li>预置国家排放核算方法,参数推荐值及排放因子,供用户快速引用。</li>
                <li>自动实时计算碳排放,企业可随时掌握碳排放的精准数据,及时对碳排放纠偏。</li>
                <li>自动生成报告,降低披露成本</li>
              </ul>
            </div>

            <el-button class="but" @click="click(0)">申请试用</el-button>

            <div class="img">
              <img src="../../assets/product/1.png" alt />
            </div>
          </el-timeline-item>
        </div>
      </el-timeline>
      <el-timeline v-if="equipment == 1">
        <div class="timeline">
          <el-timeline-item placement="top" :timestamp="equipment == 1 ? '低碳学堂' : ''">
            <div>
              涵盖顾问、知识、资讯、课程等多个版块，
              未来还将不断扩展。顾问服务解答低碳领域各种问题，其它版块覆盖
              低碳领域知识、新闻、观点、市场、计算、管理等各类服务，打造全
              方位、一站式碳顾问。
            </div>
            <el-button class="but" @click="click(1)">申请试用</el-button>

            <div class="img">
              <img src="../../assets/product/2.png" alt />
            </div>
          </el-timeline-item>
        </div>
      </el-timeline>

      <el-timeline v-if="equipment == 1" style="margin-bottom: 50px">
        <el-timeline-item timestamp="ESG管理架构" placement="top" color="#056BC6"></el-timeline-item>
        <div class="timeline" v-for="(item, index) in data" :key="index">
          <el-timeline-item placement="top" :timestamp="equipment == 1 ? item.word : ''">
            <p>{{ item.txt }}</p>
          </el-timeline-item>
        </div>
      </el-timeline>

      <div class="outer4">
        <div class="word4">我们的价值</div>
        <div class="horizontally">
          <div class="txt4">
            <div class="a">
              <div class="a1" v-for="(item, index) in VleftData" :key="index">
                <div class="box">
                  <div class="text">
                    <img :src="item.img" alt />
                  </div>
                  <div class="text2">
                    <p>{{ item.text }}</p>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="b2 BLANK">
              <img src="../../assets/product/95921514f424a521a73a2217cf49592a.png" alt />
            </div>
            <div class="a">
              <div class="a1" v-for="(item, index) in VrightData" :key="index">
                <div class="box">
                  <div class="text">
                    <img :src="item.img" alt />
                  </div>
                  <div class="text2">
                    <p>{{ item.text }}</p>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<!-- horizontally 里面 -->
          <!-- <div class="top">
          <div class="txt1">
            <p>积极响应策略-ESG管理架构</p>
            <ul>
              <li>1.战略与政策：理念、目标、方法。</li>
              <li>2.委员会机制：职能包括协调、审查及汇报机构内部的ESG实践。</li>
              <li>3.流程和指标：在业务全流程中融入ESG相关考量。</li>
              <li>3.流程和指标：在业务全流程中融入ESG相关考量。</li>
              <li>
                4.培训：宣传机构ESG目标、策略，进行ESG相关流程培训、鼓励ESG创新实践。
              </li>
              <li>
                5.信息披露：对内传递ESG成果，积极回答投资者最关心的问题，对公众披露ESG报告。
              </li>
            </ul>
          </div>
          <div class="txt2">
            <p>我们的价值-数据驱动ESG</p>
            <ul>
              <li>1.多种场景标准</li>
              <li>2.帮助企业积极应对ESG披露风险</li>
              <li>3.降低ESG运营成本和难度</li>
              <li>4.目标和周期拆解</li>
              <li>5.多渠道数据统一应用。</li>
            </ul>
          </div>
          </div>-->
      <!-- <div class="outer2">
        <div class="word2 BLANK">ESG</div>
        <div class="horizontally">
      
          <div class="bottom">
            <el-timeline>
              <el-timeline-item timestamp="解决方案" placement="top" color="#056BC6"></el-timeline-item>
              <div class="timeline">
                <el-timeline-item placement="top" :timestamp="equipment == 1 ? '控排检测' : ''">
                  <el-card>
                    <h4 class="BLANK">控排检测</h4>
                    <p>远程抄表；生产监控；官网监测；水质监测；环境监测；视频监控。</p>
                  </el-card>
                </el-timeline-item>
              </div>
              <div class="timeline">
                <el-timeline-item placement="top" :timestamp="equipment == 1 ? '跟踪与预警' : ''">
                  <el-card>
                    <h4 class="BLANK">跟踪与预警</h4>
                    <p>
                      跟踪目标：指标定量；责任主体；行动路线；涉及领域
                      <i class="el-icon-d-arrow-right"></i>
                      选择基准：评价模型；评价周期；优先级；严重程度
                      <i class="el-icon-d-arrow-right"></i>
                      日常追踪：日常填报；周月度会议；负面信息汇集
                      <i class="el-icon-d-arrow-right"></i>
                      实时计算填表：进度比例；量性转换；期望对比；影响因子
                      <i
                        class="el-icon-d-arrow-right"
                      ></i>
                      风险研判：风险原因；风险范围；应对措施；责任主体
                      <i
                        class="el-icon-d-arrow-right"
                      ></i>
                      日常追踪：邮件；App推送；驾驶舱提醒。
                    </p>
                  </el-card>
                </el-timeline-item>
              </div>
              <div class="timeline">
                <el-timeline-item placement="top" :timestamp="equipment == 1 ? '报告生产' : ''">
                  <el-card>
                    <h4 class="BLANK">报告生产</h4>
                    <p>筛选ESG数据库；选择ESG标准；分析数据；生成报告。</p>
                    <p>1.报告和架构：匹配准确、确定主题。</p>
                    <p>2.生产报告：分类归纳辅助人工编写NLU自动根据指标串联数据。</p>
                    <p>3.审阅调整：字句纠错、数据溯源、文章排版、报告归档。</p>
                  </el-card>
                </el-timeline-item>
              </div>

              <el-timeline-item timestamp="案例介绍" placement="top" color="#056BC6"></el-timeline-item>
              <div class="timeline">
                <el-timeline-item placement="top" :timestamp="equipment == 1 ? 'ESG驾驶舱' : ''">
                  <el-card>
                    <h4 class="BLANK">ESG驾驶舱</h4>
                    <el-button class="but" @click="click(3)">联系我们</el-button>
                    <div class="drive">
                      <img src="../../assets/product/drive.png" alt />
                    </div>
                  </el-card>
                </el-timeline-item>
              </div>
            </el-timeline>
          </div>
        </div>
      </div> -->

      <div class="outer5">
        <div class="word5">其他应用案例</div>
        <div class="horizontally">
          <div class="div" @click="click(5, item)" v-for="(item, index) in list" :key="index">
            <div class="img">
              <!-- seriesName 图片 product: Array(1)   productContent-->
              <img :src="item.url" alt />
            </div>
            <div class="txt">
              <p class="p1">{{ item.productName }}</p>
              <p class="p2">
                <span v-html="item.brief"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!isShow">
      <div class="banner banner1">
        <div class="word1">案列展示</div>
        <div class="txt1">成为全球领先的碳核查平台</div>
      </div>
      <div class="horizontally" style="margin-top: 100px; margin-bottom: 100px">
        <newsDetails :news_val="news_val" :type="isShow"></newsDetails>
      </div>
    </div>

    <el-button
      type="primary"
      plain
      round
      class="el_but"
      @click="backspace"
      style="right: 2%; top: 80%; position: fixed"
      v-show="!isShow"
    >返回</el-button>
  </div>
</template>

<script>
import newsDetails from "../journalism/components/newsDetails.vue";
import img1 from "../../assets/product/4.png";
import img2 from "../../assets/product/5.png";
import img3 from "../../assets/product/6.png";
import img4 from "../../assets/product/7.png";
import img5 from "../../assets/product/8.png";
import img10 from "../../assets/product/10.png";
import img11 from "../../assets/product/11.png";
import img12 from "../../assets/product/12.png";
import img13 from "../../assets/product/13.png";
import img14 from "../../assets/product/14.png";
import { getfindVoAlL } from "../../api/api";
export default {
  data () {
    return {
      data: [
        {
          img: img1,
          word: "战略与策略",
          txt: "理念、目标、方法",
        },
        {
          img: img2,
          word: "委员会机制",
          txt: "包括协调、审查及汇报机构内部的ESG实践",
        },
        {
          img: img3,
          word: "流程和指标",
          txt: "在业务全流程中融入 ESG相关考量",
        },
        {
          img: img4,
          word: "培训",
          txt: "宣传机构ESG目标、策略， 进行ESG相",
        },
        {
          img: img5,
          word: "信息披露",
          txt: "对内传递ESG成果，积极回答投资者最关心的问题，对公众披露ESG报告",
        },
      ],
      VleftData: [
        {
          img: img10,
          text: "多种场景标准",
        },
        {
          img: img11,
          text: "降低ESG运营成本和难度",
        },
        {
          img: img12,
          text: "多渠道数据统一应用",
        },
      ],
      VrightData: [
        {
          img: img13,
          text: "帮助企业积极应对ESG披露风险",
        },
        {
          img: img14,
          text: "目标和周期拆解",
        },
      ],
      homeURL: "http://coriander.skyezone.com.cn/login",
      schoolURL: "http://merak.skyezone.com.cn/login",
      list: [],
      istrue: true,
      news_val: {},
      isShow: true,
      scrollTop: "",
      equipment: 0, //0 pc  1 移动
    };
  },

  components: { newsDetails },
  mounted () {
    _hmt.push(['_trackEvent', '产品介绍', '进入']);
    getfindVoAlL({}).then((res) => {
      this.list = res.data[0].product;
    });
    const that = this;
    this.load_equipment();
  },
  methods: {
    // 判断设备
    load_equipment () {
      if (document.body.clientWidth < 1024) {
        this.equipment = 1;
      }
    },
    click (val, txt) {
      if (val == 0) {
        //0双碳  1学堂   3 联系我们   5案列详情
        window.open(this.homeURL);
      } else if (val == 1) {
        window.open(this.schoolURL);
      } else if (val == 5) {
        var app = document.getElementById("app");
        this.scrollTop = app.scrollTop;
        this.isShow = !this.isShow;
        this.news_val = txt;
        app.scrollTop = 0;
        // console.log(txt.productContent);
        // let routeUrl = this.$router.resolve({
        //   name: "CaseDetails",
        //   query: {
        //     companyName: "名称",
        //   },
        // });
        // window.open(routeUrl.href, "_blank");
      } else {
        this.$router.push("/contactUs");
      }
    },
    backspace () {
      this.isShow = !this.isShow;
      setTimeout(
        function (a, b) {
          var app = document.getElementById("app");
          app.scrollTop = a;
        },
        1,
        this.scrollTop,
        1
      );
    },
  },
};
</script>
<style lang='less' scoped>
@import './index.less';
</style>
